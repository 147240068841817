<template>
  <v-row>
    <v-col class="d-flex justify-center">
      <v-card width="500">
        <v-card-title class="d-flex justify-center"
          >Factuur betalen</v-card-title
        >
        <v-list>
            <v-list-item>
            <v-list-item-content>Naam:</v-list-item-content>
            <v-list-item-content>{{
              invoice.name
            }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Factuurnummer:</v-list-item-content>
            <v-list-item-content>{{
              invoice.invoiceNumber
            }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Datum:</v-list-item-content>
            <v-list-item-content>{{
              toDateString(invoice.date)
            }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Vervaldatum:</v-list-item-content>
            <v-list-item-content>{{
              toDateString(invoice.dueDate)
            }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Bedrag:</v-list-item-content>
            <v-list-item-content>{{
              toCurrency(invoice.debit)
            }}</v-list-item-content>
          </v-list-item>
          <div class="d-flex justify-center">
          <v-btn :disabled="!invoice.debit>0" @click="createPayment" color="primary">Betaal nu</v-btn>
         </div>
          <h2 class="d-flex justify-center" v-if="invoice._id&&invoice.debit===0">Deze factuur is betaald</h2>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {config} from '../config/config'
import { toCurrency, toDateString } from "../js/functions"
export default {
  name: "payment",
  data() {
    return {
      invoice: {name: "", invoiceNumber: "", date: "", dueDate: "", debit: 0 }
    }
  },
  methods: {
    toDateString(n) {
      if(n)return toDateString(n)
      return
    },
    toCurrency(n) {
      return toCurrency(n)
    },
    async fetchInvoice(_id) {
      console.log(_id)
        let data = JSON.stringify({_id:_id})
       let res = await fetch(`${config.payments.host}/fetchInvoice/${data}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
    let response = await res.json()
      this.invoice = response[0]
    },
    async createPayment() {
       let res = await fetch(`${config.payments.host}/createPayment`, {
        body: JSON.stringify(this.invoice),
        method: "POST",
        headers: { "Content-Type": "application/json" }
      })
    let response = await res.text()
    window.location.href = response
    }
  },
  async mounted() {
    await this.fetchInvoice(this.$route.params.id)
  }
}
</script>

<style scoped>
</style>